import { makeAutoObservable } from 'mobx'
import { getProfilePic } from '../util/profilePictureHelper'

export default class App {
  profilePictures = {}
  screenSize = 'md'

  constructor() {
    makeAutoObservable(this)
  }

  async getProfilePicture(graphAccessToken, UPNorId) {
    if (UPNorId === 'Teamflect') {
      return 'https://teamblee.blob.core.windows.net/teamflect/logo-pink-140x140.png'
    }

    if (this.profilePictures[UPNorId]) {
      return this.profilePictures[UPNorId]
    }
    try {
      const profilePicture = await getProfilePic(graphAccessToken, UPNorId)
      this.profilePictures[UPNorId] = profilePicture
      return profilePicture
    } catch (err) {
      console.log(err)
      return null
    }
  }

  updateScreenSize = () => {
    const width = window.innerWidth
    if (width < 768) {
      this.screenSize = 'sm'
    } else if (width < 1024) {
      this.screenSize = 'md'
    } else if (width < 1280) {
      this.screenSize = 'lg'
    } else {
      this.screenSize = 'xl'
    }
  }
}
