import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'mobx-react'
import stores from './stores/index.js'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ToastContainer, toast } from 'react-toastify'

import * as Sentry from '@sentry/browser'

import { createContext } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { checkError } from './filterErrorHelper'
import { SettingsContextProvider } from './context/SettingsContextProvider'
import { mixpanelCustomEvent } from './util/mixpanelHelper'

export const AppContext = createContext(null)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a61d5d4e7ed240eea4f64f74b2ec2da7@o321210.ingest.sentry.io/4504253158195201',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    //debug: true,
    beforeSend(event, hint) {
      const error = hint.originalException
      return checkError(error, event)
    },
    environment: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 'production' : process.env.REACT_APP_ENVIRONMENT
  })
}

window.clipboard = obj => {
  try {
    navigator.clipboard.writeText(JSON.stringify(obj))
  } catch (e) {
    mixpanelCustomEvent('Clipboard Copy Error', { error: e })
  }
}

ReactDOM.render(
  <AppContext.Provider values={stores}>
    <Provider {...stores}>
      <>
        <ToastContainer
          style={{
            zIndex: 999999
          }}
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
        <App />
      </>
    </Provider>
  </AppContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
