// #region Dependencies ===================================================================

import React, { useEffect } from 'react'
import { useSettings } from './context/SettingsContextProvider'
import { HashRouter, Route, Switch } from 'react-router-dom'

import { RefreshModal } from './components/RefreshModal/RefreshModal'
import Loading from './components/Loading'

import { SettingsContextProvider } from './context/SettingsContextProvider'

// #endregion

const AppInner = ({ isRefreshing }) => {
  // #region States and Variables =========================================================
  // Containers
  const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

  // Pages
  const Page404 = React.lazy(() => import('./views/Pages/Page404'))
  const Page500 = React.lazy(() => import('./views/Pages/Page500'))
  const Maintenance = React.lazy(() => import('./views/Maintenance/Maintenance'))

  // #endregion

  // #region Mounting Functions ===========================================================

  return (
    <SettingsContextProvider>
      {isRefreshing && <RefreshModal />}
      <HashRouter>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path='/404' name='Page 404' render={props => <Page404 {...props} />} />
            <Route exact path='/500' name='Page 500' render={props => <Page500 {...props} />} />
            <Route path='/' name='Home' render={props => <DefaultLayout {...props} />} />
            <Route path='/maintenance' name='Maintenance' render={props => <Maintenance />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </SettingsContextProvider>
  )
}

export default AppInner
